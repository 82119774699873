/* @import url('https://fonts.googleapis.com/css2?family=Zilla+Slab&display=swap'); */
.storyComments {
    width: 100%;
    position: relative;
    height: auto;
}

.storyComments h5 {
    font-family: 'Saira Condensed', sans-serif;
    font-weight: bold;
    padding-left: 0.6rem;
    font-size: 1.2rem;
    padding-bottom: 0.8rem;
    border-bottom: rgba(218, 218, 218, 0.4) solid 1px;
    margin-bottom: 2rem;
}

.comment-Wrapper {
    position: relative;
}

.comment-item {
    margin-bottom: 30px;
    padding-bottom: 15px;
    border-bottom: rgba(218, 218, 218, 0.4) solid 1px;
}

.comment-top-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.comment-top-block section svg {
    margin-right: 8px;
    font-size: 1.2rem;
    color: rgb(109, 109, 109);
    cursor: pointer;
}

.comment-top-block section {
    display: flex;
}

.comment-top-block section img {
    border-radius: 50%;
    width: 39px;
    height: 39px;
    margin-right: 10px;
}

.comment-top-block section div {
    display: flex;
    flex-direction: column;
}

.comment-author-username {
    font-size: 0.9rem;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.comment-createdAt {
    font-weight: 550;
    color: gray;
    font-size: 0.8rem;
}

.comment-content {
    padding-left: 4px;
    height: auto;
    font-size: 1rem;
    margin: 1.2rem 0;
    font-family: 'Zilla Slab', serif;
}

.comment-bottom-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 12px;
    padding-left: 4px;
}

.commentLike-wrapper i {
    margin-right: 10px;
    cursor: pointer;
    font-size: 1.3rem;
    color: rgb(65, 65, 65);
}

.commentlikeCount {
    font-family: 'Inter', sans-serif;
    font-weight: bold;
    color: rgb(65, 65, 65);
    font-size: 1rem;
}

.no-response {
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 0.8rem;
    line-height: 26px;
    color: rgb(112, 112, 112);
}